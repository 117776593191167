<template>
  <div class="AnfrageBtn d-block d-sm-none">
    <div class="container">
      <div class="row">
        <div class="col">
          <router-link alt="Angebot einholen" title="Angebot einholen" :to="{ name: 'requestform' }" class="mt-auto btn-sm btn btn-primary btn-lg">Angebot anfordern</router-link>
        </div>
      </div>

    </div>
  </div>

</template>
<style>
.AnfrageBtn {
  z-index: 8;
  position: fixed;
  bottom:5px;;
}
</style>
<script>


export default {
  name: 'AnfrageButton',
  components: {

  },
  data() {
    return {

     };
  }
}
</script>
