import ApiRoutes from "./routes.js"


export default {
  install: (app) => {
    app.mixin({
      data() {
        return {
          CmsApi: new ApiRoutes()
        }
      },
    })
  }
}
