<template>
    <div class="bg-light py-4">
      <div class="container min-vh-100">
        <div class="row">
          <div v-for="post in posts" :key="post" class="mt-4">
            <BlogPostPreview
            :link="'/umzugsratgeber/'+ post.slug"
            :linktitle="post.title.rendered"
            textside="right"
            :imgsrc="(post._embedded['wp:featuredmedia'].length > 0)?post._embedded['wp:featuredmedia'][0].source_url:null"
            :imgalt="(post._embedded['wp:featuredmedia'].length > 0)?post._embedded['wp:featuredmedia'][0].alt_text:null"
            >
            <h2><a :title="post.title.rendered" :href="'/umzugsratgeber/'+ post.slug"><div v-html="post.title.rendered"></div></a></h2>
            <div v-html="post.excerpt.rendered"></div>
          </BlogPostPreview>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import BlogPostPreview from '@/components/BlogPostPreview.vue'

export default {
  name: 'BlogPreview',
  components: {
    BlogPostPreview
  },
  props: {
    count: {
      type: Number,
      default: 4
    }
  },
  data: function() {
    return {
      posts: []
    }
  },
  mounted: function() {
    this.CmsApi.GetArticles().then((data) => {
      if(data != null) {
        this.posts = data
        if(this.posts.length > this.count) {
          this.posts = this.posts.slice(0, this.count)
        }
      }
    }).catch(() => {
      // console.log(slug)
    })

  }
}
</script>
