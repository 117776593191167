<template>
  <div class="mt-4">
    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Umzüge und Entrümpelungen in München und Umgebung",
          "image": "logo.webp",
          "review": [
            {
              "@type": "Review",
              "reviewBody": "{{ review.rev_text }}",
              "author": { "@type": "Person", "name": "{{ review.rev_name }}" },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "{{ review.rev_rating }}",
                "bestRating": "{{ review.rev_rating }}",
                "worstRating": "{{ review.rev_rating }}"
              },
              "datePublished": "2022-12-02",
              "name": "{{ review.rev_source }}"
            }
          ],
          "brand": {
            "@type": "Brand",
            "name": "Umzug Bayern"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "{{ review.rev_rating }}",
            "ratingCount": "1",
            "bestRating": "5",
            "worstRating": "1"
          }
        }
      </component>
    </teleport>
    <MDBCard>
      <MDBCardHeader>
        <span v-for="index in 5" :key="index">
          <MDBIcon icon="star" iconStyle="fas" class="text-primary"/>
        </span>
        <span class="h5 text-nowrap mx-2"> {{ review.rev_name }}</span>
      </MDBCardHeader>
      <MDBCardBody>
        <p>{{ review.rev_text }}<br/><small>{{ review.rev_source }}</small></p>
        <p class="text-end"><small><a :href="review.rev_link" rel="nofollow"  class="text-right pull-right align-right" :title="review.rev_source + ' ansehen'">Bewertung ansehen</a></small></p>
      </MDBCardBody>
    </MDBCard>
  </div>
</template>
<script>
import { MDBCard, MDBCardBody, MDBCardHeader, MDBIcon  } from "mdb-vue-ui-kit"

export default {
  name: 'Review',
  props: {
    review: {
      rev_name: {
        type: String,
        required: true
      },
      rev_text: {
        type: String,
        required: true
      },
      rev_rating: {
        type: Number,
        required: true
      },
      rev_link: {
        type: String,
        required: true
      }
    }
  },
  components: {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBIcon
  },
  data() {
    return {

    };
  }
}
</script>
