import axios from 'axios'
import store from '../store'
import router from '../router'

export default class UmzugBayernApi {

  static async Login(email, password) {
    return await axios.post(process.env.VUE_APP_BE_API_LOGIN, {
      email: email,
      password: password,
      application: 'web',
      Authorization: process.env.VUE_APP_BE_API_AUTH,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      if (response.data.user !== undefined) {
        return response.data.user
      } else {
        return null
      }
    }).catch((/*error*/) => {
      //Vue.log(error)
      return null
      /*this.$gtag.event('LoginError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      }) */
    })

  }

  static async Register(form, callback, errorcallback) {
    return await axios.post(process.env.VUE_APP_BE_API_REGISTER, {
      name: form.name,
      surname: form.surname,
      company: form.company,
      street: form.street,
      zip: form.zip,
      city: form.city,
      vat_id: form.vat_id,
      email: form.email,
      password: form.password,
      application: 'web',
      Authorization: process.env.VUE_APP_BE_API_AUTH,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      //Vue.log(error)
      /*this.$gtag.event('RegisterError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.data.errors !== undefined) {
        errorcallback(error.response.data.errors)
      }
    })

  }

  static async Verify(email, token, callback) {
    //console.log("Token + Email: " + email + token);
    return await axios.post(process.env.VUE_APP_BE_API_VERIFY, {
      email: email,
      token: token,
      application: 'web',
      Authorization: process.env.VUE_APP_BE_API_AUTH,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      callback(response.data)
    }).catch((/*error*/) => {
      //Vue.log(error)
      /*this.$gtag.event('VerifyError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
    })

  }

  static async Logout() {
    if (store.state.user_authenticated == false) {
      return false
    }
    return await axios.post(process.env.VUE_APP_BE_API_LOGOUT, {
        'application': 'web',
      }, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }})
      .then((response) => {
        if (response.data.success === undefined) {
          return false
        }
        if (response.data.success == 'success') {
          return true
        } else {
          return false
        }
      })
      .catch(() => {
        return false
      })
  }

  static async ForgottenPassword(data, callback, errorcallback) {
    return await axios.post(process.env.VUE_APP_BE_API_FORGOTTEN_PASSWORD, {
      email: data.email,
      email_confirmation: data.email_confirmation,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      if(response.data.success == 'success') {
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('ForgottenPasswordError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      }
    })
  }

  static async PasswordReset(data, callback, errorcallback) {
    return await axios.post(process.env.VUE_APP_BE_API_PASSWORD_RESET, {
      email: data.email,
      token: data.token,
      password: data.password,
      password_confirmation: data.password_confirmation,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      if(response.data.success == 'success') {
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      if(error.response !== undefined) {
        errorcallback(error.response)
      }
      /*this.$gtag.event('PasswordResetError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
    })
  }

  static async AddFavorite(request_id) {
    if (store.state.user_authenticated == false) {
      return false
    }
    return await axios.post(process.env.VUE_APP_BE_API_ADD_FAVORITE, {
        'application': 'web',
        'request_id': request_id,
      }, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }})
      .then((response) => {
        if (response.data.success === undefined) {
          return false
        }
        if (response.data.success == 'success') {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        /*this.$gtag.event('AddFavoriteError', {
          'event_category' : 'API_Error',
          'event_label' : JSON.stringify(error)
        })*/
        if(error.response.status == 401 || error.response.status == 403) {
          // console.log(error.response.status);
          store.dispatch('logout', router.currentRoute._rawValue)
        }
        return false
      })
  }

  static async RemoveFavorite(request_id) {
    if (store.state.user_authenticated == false) {
      return false
    }
    return await axios.post(process.env.VUE_APP_BE_API_REMOVE_FAVORITE, {
        'application': 'web',
        'request_id': request_id,
      }, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }})
      .then((response) => {
        if (response.data.success === undefined) {
          return false
        }
        if (response.data.success == 'success') {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        /*this.$gtag.event('RemoveFavoriteError', {
          'event_category' : 'API_Error',
          'event_label' : JSON.stringify(error)
        })*/
        if(error.response.status == 401 || error.response.status == 403) {
          //console.log(error.response.status);
          store.dispatch('logout', router.currentRoute._rawValue)
        }
        return false
      })
  }

  static async GetFavorites(callback) {
    const token = store.getters.getState.user.token
    axios.get(process.env.VUE_APP_BE_API_GET_FAVORITES, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((/*error*/) => {
      /*this.$gtag.event('GetFavoritesError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async GetItems(callback) {
    axios.get(process.env.VUE_APP_BE_API_GET_ITEMS, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((/*error*/) => {
      /*this.$gtag.event('GetItemsError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      //Vue.log(error)
      //console.log(error)
    })
  }


  static async GetRequests(callback) {
    const token = store.getters.getState.user.token
    axios.get(process.env.VUE_APP_BE_API_INDEX_REQUESTS, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('GetRequestsError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        console.log(error.response.status);
        store.dispatch('logout', router.currentRoute._rawValue)
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async SearchRequests(data, callback, errorcallback) {
    const token = store.getters.getState.user.token
    //console.log(data.query)
    axios.post(process.env.VUE_APP_BE_API_SEARCH_REQUESTS, data, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('SearchRequestsError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        //console.log(error.response.status);
        store.dispatch('logout', router.currentRoute._rawValue)
      } else {
        if(error.response !== undefined) {
          if(error.response.status == 401 || error.response.status == 403) {
            //console.log(error.response.status);
            store.dispatch('logout', router.currentRoute._rawValue)
          }
          errorcallback(error.response)
        }
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async StripeCheckout(data, callback, errorcallback) {
    const token = store.getters.getState.user.token
    //console.log(data.query)
    axios.post(process.env.VUE_APP_BE_API_STRIPE_CHECKOUT, data, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('StripeCheckoutError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        if(error.response.status == 401 || error.response.status == 403) {
          //console.log(error.response.status)
          store.dispatch('logout', router.currentRoute._rawValue)
        }
        errorcallback(error.response)
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async GetRequest(requestid, callback) {
    const token = store.getters.getState.user.token
    axios.get(process.env.VUE_APP_BE_API_GET_REQUEST + "/" + requestid, {
      headers: {
        'request_id': requestid,
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('GetRequestError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        //console.log(error.response.status)
        store.dispatch('logout', router.currentRoute._rawValue)
      }
    })
  }

  static async GetCity(cityslug, callback) {
    axios.get(process.env.VUE_APP_BE_API_GET_CITY + "/" + cityslug, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((/*error*/) => {
      /*this.$gtag.event('GetCityError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async StoreRequest(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_REQUEST, data, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('StoreRequestError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async StoreFailedRequest(data) {
    axios.post(process.env.VUE_APP_BE_API_STORE_FAILED_REQUEST, data, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }})
  }

  static async StoreFile(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_FILE, data, {
        headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('StoreFileError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async GetImage(id, callback) {
    const token = store.getters.getState.user.token
    axios.get(process.env.VUE_APP_BE_API_GET_FILE + "/" + id, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data.url)
    }).catch((error) => {
      /*this.$gtag.event('GetImageError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        //console.log(error.response.status)
        store.dispatch('logout', router.currentRoute._rawValue)
      }
    })
  }

  static async StoreOffer(offerdata, callback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_OFFER, offerdata, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token
    }

    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('StoreOfferError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        //console.log(error.response.status)
        store.dispatch('logout', router.currentRoute._rawValue)
      }
    })
  }

  static async Contact(form) {
    return await axios.post(process.env.VUE_APP_BE_API_CONTACT, {
      form: form,
      name: form.name,
      surname: form.surname,
      email: form.email,
      message: form.message,
      msg: form.msg,
      application: 'web',
      Authorization: process.env.VUE_APP_BE_API_AUTH,
      headers: {
        Authorization: process.env.VUE_APP_BE_API_AUTH,
      }
    }).then((response) => {
      if (response.data.success !== undefined) {
        return response.data.success
      } else {
        return null
      }
    }).catch(function() {
      //Vue.log(error)
      return null;
    })

  }

  static async GetUserProfile(callback) {
    const token = store.getters.getState.user.token
    axios.get(process.env.VUE_APP_BE_API_GET_USER_PROFILE, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('GetUserProfileError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        console.log(error.response.status);
        store.dispatch('logout', router.currentRoute._rawValue)
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async StoreUserProfile(user, callback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_USER_PROFILE, user, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token
    }
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      /*this.$gtag.event('StoreUserProfileError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response.status == 401 || error.response.status == 403) {
        //console.log(error.response.status)
        store.dispatch('logout', router.currentRoute._rawValue)
      }
    })
  }

  static async StoreUserLogo(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_USER_LOGO, data, {
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('StoreUserLogoError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async RemoveUserLogo(callback, errorcallback) {
    axios.get(process.env.VUE_APP_BE_API_REMOVE_USER_LOGO, {
      headers: {
        'Authorization': 'Bearer ' + store.state.user.token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('RemoveUserLogoError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
      //Vue.log(error)
      //console.log(error)
    })
  }


  static async GetAppointmentsAt(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_APPOINTMENTS_AT, data, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }
    }).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('GetAppointmentsAtError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async GetAppointments(callback, errorcallback) {
    axios.get(process.env.VUE_APP_BE_API_APPOINTMENTS, {
      headers: {
        'Authorization': 'Bearer ' + store.state.user.token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('GetAppointmentsError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async StoreAppointment(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_APPOINTMENT, data, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }}).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      /*this.$gtag.event('StoreUserLogoError', {
        'event_category' : 'API_Error',
        'event_label' : JSON.stringify(error)
      })*/
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
      //Vue.log(error)
      //console.log(error)
    })
  }

  static async GetInvoices(callback, errorcallback) {
    axios.get(process.env.VUE_APP_BE_API_GET_INVOICES, {
      headers: {
        'Authorization': 'Bearer ' + store.state.user.token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      //console.log(response)
      if(response.data.success == 'success') {
        //console.log(response.data)
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
    })
  }

  static async StoreInvoice(data, callback, errorcallback) {
    axios.post(process.env.VUE_APP_BE_API_STORE_INVOICE, data, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.state.user.token,
        'Accept': 'application/json',
      }}).then((response) => {
      if(response.data.success == 'success') {
        callback(response.data)
      } else {
        errorcallback(response.data)
      }
    }).catch((error) => {
      if(error.response !== undefined) {
        errorcallback(error.response)
      } else {
        errorcallback()
      }
    })
  }


}
