<template>
  <div v-if="cookieset == false" class="CookieConsentBanner border border-primary">
    <div class="container">
      <div class="row px-4 align-center align-self mt-4 mb-4">
        <div class="col-md-10 text-black">
          <p>Wir möchten die Informationen auf dieser Webseite und auch unsere Leistungsangebote auf Ihre Bedürfnisse anpassen. Zu diesem Zweck setzen wir sog. Cookies ein. Weitere Informationen erhalten Sie in unserer <strong><router-link class="text-black" :to="{ name: 'datenschutz' }">Datenschutzerklärung</router-link></strong>.</p>
        </div>
        <div class="col-md-2">
          <MDBBtn v-on:click="onSubmit" class="btn-primary btn-block mb-4">Okay</MDBBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.CookieConsentBanner {
  font-size: small;
  z-index: 9990;
  position: sticky;
  bottom:0;
  position:fixed;
  overflow-y:hidden;
  overflow-x:hidden;
  max-height: 100%;
  width:100%;
  opacity: 1;
  color: #2c3e50;
  background-color: #FFFF;
}

</style>
<script>
import { MDBBtn } from "mdb-vue-ui-kit"
import { useState } from "vue-gtag-next"

export default {
  name: 'CookieConsentBanner',
  components: {
    MDBBtn
  },
  data() {
    return {
      cookieset: false
     };
  },
  created: function(){
    if(this.$cookie.getCookie('UmzugBayernCookieConsent') == 'true') {
      this.cookieset = true
    } else {
      this.cookieset = false
    }
    if(this.$cookie.getCookie('UmzugBayernConsentGoogleAnalytics') == 'false') {
      this.GoogleAnalytics = false
    } else {
      this.GoogleAnalytics = true
      useState().isEnabled.value = true
    }
  },
  methods: {
    onSubmit() {
      this.$cookie.setCookie('UmzugBayernCookieConsent', true, {
        expire: '180d',
        path: '/',
        domain: '',
        secure: '',
        sameSite: '',
      }) //return this
      this.cookieset = true
    }
  }
}
</script>
