<template>
<div class="bg-white">
      <nav class="navbarcss" role='navigation'>
        <a href="/" class="navbar-logo mx-2" title="Startseite"><img src="../assets/logo.webp" width="180" height="70" alt="Umzug Bayern" title="Umzug Bayern Logo" loading="lazy" class="d-inline-block align-middle mr-2"></a>
        <ul class="menu no-js vertical-center">
          <li class="menu-item"><a class="active" href="#">Leistungen</a>
            <ul class="sub-menu">
              <li class="menu-item"><router-link class="nav-item dropdown-item" :to="{ name: 'unser-angebot' }" title="Unser Angebot">Unser Angebot</router-link></li>
              <li class="menu-item">
                  <a href="#">Umzug</a>
                  <ul class="sub-menu">
                    <li :class="'menu-item ' + 'umzug-planen'==currentRouteName?'active':'' "><router-link :class="('umzugsmaterialien'==currentRouteName?'active':'')" :to="{ name: 'umzug-planen' }" title="Umzug Planen"> Umzug Planen
                    </router-link ></li>
                    <li :class="'menu-item ' + 'umzug-planen'==currentRouteName?'active':'' "><router-link :class="('umzugsmaterialien'==currentRouteName?'active':'')" :to="{ name: 'umzugsfirma' }" title="Umzugsfirma">Umzugsfirma
                    </router-link ></li>
                    <li class="menu-item"><router-link :class="('umzug-muenchen-berlin'==currentRouteName?'active':'')" :to="{ name: 'umzug-muenchen-berlin' }" title="Umzug Berlin München">Umzug Berlin München
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('privatumzuege'==currentRouteName?'active':'')" :to="{ name: 'privatumzuege' }" title="Privatumzüge">Privatumzüge
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('seniorenumzuege'==currentRouteName?'active':'')" :to="{ name: 'seniorenumzuege' }" title="Seniorenumzüge">Seniorenumzüge
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('studentenumzug'==currentRouteName?'active':'')" :to="{ name: 'studentenumzug' }" title="Studentenumzug">Studentenumzüge
                    </router-link></li>
                    <li class="menu-item"><router-link  class="nav-item dropdown-item" role="button" :to="{ name: 'firmenumzuege' }" title="Firmenumzüge"> Firmenumzüge
                    </router-link></li>
                    <li class="menu-item"><router-link  class="nav-item dropdown-item" role="button" :to="{ name: 'moebelmontage' }" title="Möbelmontage"> Möbelmontage
                    </router-link></li>
                    <li class="menu-item"><router-link  class="nav-item dropdown-item" role="button" :to="{ name: 'blog' }" title="Umzugsratgeber"> Umzugsratgeber
                    </router-link></li>
                  </ul>
              </li>
              <li class="menu-item">
                  <a href="#">Entrümpelung</a>
                  <ul class="sub-menu">
                    <li class="menu-item"><router-link :class="('entruempelung'==currentRouteName?'active':'')" :to="{ name: 'entruempelung' }" title="Etrümpelung">Entrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('hausentruempelung'==currentRouteName?'active':'')" :to="{ name: 'hausentruempelung' }" title="Hazsentrümpelung">Hausentrümpelungen
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('wohnungsentruempelung'==currentRouteName?'active':'')" :to="{ name: 'wohnungsentruempelung' }" title="Wohnungsentrümpelung">Wohnungsentrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('entkernung'==currentRouteName?'active':'')" :to="{ name: 'entkernung' }" title="Entkernung">Entkernung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('kellerentruempelung'==currentRouteName?'active':'')" :to="{ name: 'kellerentruempelung' }" title="Kellerentrümpelung">Kellerentrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('garagenentruempelung'==currentRouteName?'active':'')" :to="{ name: 'garagenentruempelung' }" title="Garagenentrümpelung">Garagenentrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('dachbodenentruempelung'==currentRouteName?'active':'')" :to="{ name: 'dachbodenentruempelung' }" title="Dachbodenentrümpelung">Dachbodenentrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('lagerentruempelung'==currentRouteName?'active':'')" :to="{ name: 'lagerentruempelung' }" title="Lagerentrümpelung">Lagerentrümpelung
                    </router-link></li>
                    <li class="menu-item"><router-link :class="('firmenentruempelung'==currentRouteName?'active':'')" :to="{ name: 'firmenentruempelung' }" title="Firmenentrümpelung">Firmenentrümpelung
                    </router-link></li>
                  </ul>
              </li>
              <li class="menu-item"><router-link class="nav-item dropdown-item" :to="{ name: 'tatortreinigung' }" title="Tatortreinigung">Tatortreinigung</router-link></li>
              <li class="menu-item"><router-link class="nav-item dropdown-item" :to="{ name: 'messeaufbau' }" title="Messe Transport und Aufbau">Fachmessen</router-link></li>
            </ul>
          </li>
          <li class="menu-item"><router-link :class="('umzugsmaterialien'==currentRouteName?'active':'')" :to="{ name: 'umzugsmaterialien' }" title="Umzugsmaterial" >Umzugsmaterial</router-link></li>
          <li class="menu-item"><router-link :class="('faq'==currentRouteName?'active':'')" :to="{ name: 'faq' }" title="FAQ" >FAQ</router-link></li>
          <li class="menu-item"><router-link :class="('preise'==currentRouteName?'active':'')" :to="{ name: 'preise' }" title="Preise" >Preise</router-link></li>
          <li class="menu-item"><router-link :class="('bewertungen'==currentRouteName?'active':'')" :to="{ name: 'bewertungen' }" title="Bewertungen" >Bewertungen</router-link></li>
          <li class="menu-item"><router-link :class="('blog'==currentRouteName?'active':'')" :to="{ name: 'blog' }" title="Umzugsratgeber" >Umzugsratgeber</router-link></li>
          <li class="menu-item"><router-link :class="('kontakt'==currentRouteName?'active':'')" :to="{ name: 'kontakt' }" title="Kontakt" >Kontakt</router-link></li>
          <li><router-link @click="OnlineFormClicked" title="Angebot einholen" :to="{ name: 'requestform' }" >
            <div title="Angebot einholen" alt="Angebot einholen" class="btn btn-outline-primary btn-lg">
            Angebot einholen
            </div>
          </router-link></li>
        </ul>
      </nav>
</div>
</template>

<script>
/* eslint-disable */
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    Name: "NavbarCSSOnly",
    components: {

    },
    computed: {
      userLoggedIn() {
        return this.$store.state.user_authenticated
      },
      userVerified() {
        return this.$store.state.user_verified
      },
      userName() {
        return this.$store.state.user.name
      },
      currentRouteName() {
          return this.$route.name
      },
      currentRouteName() {
        return useRoute().name;
      }
  },
  methods: {
    onLogOut() {
      this.$store.dispatch('logout')
    },
    OnlineFormClicked() {
      this.$gtag.event('OnlineForm', {
        'event_category' : 'Interaction',
        'event_label' : 'online form link clicked on top menu'
      })
    }
  },
  setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      const dropdown2 = ref(false);
      const dropdown3 = ref(false);
      const dropdown4 = ref(false);
      const dropdown5 = ref(false);
      return {
        collapse1,
        dropdown1,
        dropdown2,
        dropdown3,
        dropdown4,
        dropdown5
      }
    }
  };
</script>

<style scoped lang="scss">
/* eslint-disable */
/* setup */
* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  transition:all 60ms ease;
}

/* variables */
$primary: #246f80;
$secondary: #3fb2cd;
$white: #ffffff;
$gray: #757575;
$menuHeight      : 3rem;
$subHeight       : 2rem;
$subwidth        : 200px;
$subPadding      : 0.35rem 0.25rem 0.25rem;
$suboffset       : 0.35rem; /* offset the top padding */


@mixin cf() {
  *zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
	 clear: both;
	}
}

%blockRel {
  display:block;
  position: relative;
}

%inBlockRel {
  display:inline-block;
  position: relative;
}


/*
Essential Styles
- - - - - - - - - - - -
I like to keep the specificity low by using tags to make it easy to override the basic strucuture, if needed.  Adding an id to the root element would offer more isolation.

*/


.menu {

  postion:relative;
  list-style:none;
  /*float: right;*/
  padding:0;
  margin:0;
  z-index: 99;

  li {
    @extend %inBlockRel;
    float: left;
  }
  a {
      @extend %inBlockRel;
      height:$menuHeight;
      line-height:$menuHeight;
      width:100%;
    }

  ul { /* drop down menu */
    display: none;
    position: absolute;
    top: 100%;
    left : 0px;
    width: $subwidth;
    min-width:$subwidth;
    padding: 0;
    margin:0;

    ul { /* nested drops */
      top: (0 - $suboffset);
      left: 100%;
    }

    li {
      @extend %blockRel;
      float: left;
      width:100%;
    }

    a {
      @extend %blockRel;
      height: $subHeight;
      line-height: $subHeight;
    }

  }

  li:hover > .sub-menu {
      display: block;
    }

  @include cf();
}


$navbg : #ffffff;
$topcolor : $primary;
$topactivecolor : $secondary;
$subbg : $primary;
$sublink : $white;
$sublinkactivebg : #000000;
$nestedbg : $primary;
$currentcolor : $topcolor;

/* THEME  */
.navbarcss {

  background: $navbg;
  position: relative;
  @include cf();
}

.vertical-center {
  /*margin: 0;*/
  /*float: right;*/
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar-logo {

}

.menu {
  /*float: right;*/
  right: 0;
  /*width: 100%;*/

  li {
    border-right:1px dotted #ECECEC;
    /*width: 25%;*/
    &:last-child {
      border-right:none;
    }
  }

  a {
    color :$topcolor;
    font-size :0.75rem;
    text-decoration :none;
    text-transform :uppercase;
    padding: 0 0.5rem;

    &:hover,
    &.active {
      color : $topactivecolor;
      /* border-bottom: 1px solid; */
    }
  }

    %arrow {
      line-height:1em;
      font-size:6px;
      padding-left:6px;
      position:relative;
      top:-2px;
    }

  /* Change this in order to change the Dropdown symbol */
  .sub-menu li > a:after {
    @extend %arrow;
    content: ' \25B6';

  }

  li > a:after {
    @extend %arrow;
    content: ' \25BC';

  }
  li > a:only-child:after,
  .sub-menu li > a:only-child:after {
    content: '';
  }

   /* Drop Down Menus */
  .sub-menu {
    background:$subbg;
    padding :$subPadding;
    li {
      border-right:none;
    }
    a {
      color:$sublink;
      /* padding: 0.35rem ($menuHeight / 2); */
      &:hover {
        background: $sublinkactivebg;
        border-bottom: none;
      }
    }
    &:before {
      content : '';
      border-bottom: 14px solid $subbg;
      border-right:14px solid transparent;
      position : absolute;
      top: -14px;
      left: 0;
    }

    /* Nested Drop Down Menus */
    .sub-menu {
        background : $nestedbg;
        &:before {
          content : ' ';
          border-right: 14px solid $nestedbg;
          border-bottom:14px solid transparent;
          position : absolute;
          top: 0;
          left: -14px;
        }

      li a:hover,
      li a:active {
          background: $subbg;
        }
    }

  } /* end sub-menus */

  .active,
  .current_page_item a,
  .current-menu-item a {
    color : $currentcolor;
  }

}



</style>
