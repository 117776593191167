//import 'mdb-vue-ui-kit/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import VueGtag from "vue-gtag-next"
//import { createSchemaOrg } from "@vueuse/schema-org"
import { createMetaManager } from 'vue-meta'
import cmsapi from './cms_api'

// set default config
VueCookieNext.config({ expire: '180d' })

/*
const SchemaOrgPlugin = createSchemaOrg({
  position: 'head',
  meta: () => {
    return {
      host: "host.de",
      url: "host.de",
      path: "host.de",
      currency: "host.de",
      image: "",
      inLanguage: "",
      title: "",
      description: "",
      datePublished: "",
      dateModified: "",
      defaultLanguage: "",
      defaultCurrency: "",
      canonicalHost: "",
      canonicalUrl: "",
    }
  },
  updateHead: (fn) => {
    console.log(fn);
    console.log(fn.value)
    return fn
  }
}) */

createApp(App).use(router).use(store).use(cmsapi).use(VueCookieNext).use(VueGtag, {
  isEnabled: false,
  property: {
    id: "G-0376EZXYBL"
  }
}, router).use(createMetaManager(
  false,
  {
    keywords: {
      tag: "meta"
    },
    robots: {
      tag: "meta"
    },
    description: {
      tag: "meta"
    },
    og: {
      group: true,
      namespacedAttribute: true,
      tag: "meta",
      keyAttribute: "property"
    },
    twitter: {
        group: true,
        namespacedAttribute: true,
        tag: "meta",
    }
  }

)).mount('#app')



//console.log("generate schema");
//SchemaOrgPlugin.generateSchema()
//console.log("force refresh");
//SchemaOrgPlugin.forceRefresh()
//console.log(SchemaOrgPlugin);
//console.log(SchemaOrgPlugin.ctx);
