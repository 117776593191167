import BaseApi from "./baseapi.js"

export default class ApiRoutes extends BaseApi {

GetArticles()
{

  return this.ApiCall({
    url: process.env.VUE_APP_BE_CMS_API_URL + '/posts?_embed&per_page=100',
    method: 'GET',
    paramnames: [],
    paramvalues: [],
    data: null,
    auth: false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  });
}

GetArticleBySlug(slug)
{

  return this.ApiCall({
    url: process.env.VUE_APP_BE_CMS_API_URL + '/posts/?slug=' + slug + '&_embed',
    method: 'GET',
    paramnames: [],
    paramvalues: [],
    data: null,
    auth: false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  });
}

GetPageBySlug(slug)
{

  return this.ApiCall({
    url: process.env.VUE_APP_BE_CMS_API_URL + '/pages/?slug=' + slug + '&_embed',
    method: 'GET',
    paramnames: [],
    paramvalues: [],
    data: null,
    auth: false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  });
}

SendContactForm(formdata)
{
  return this.ApiCall({
    url: process.env.VUE_APP_BE_CMS_API_BASE + '/wp-json/contact-form-7/v1/contact-forms/4/feedback',
    method: 'POST',
    paramnames: [],
    paramvalues: [],
    data: formdata,
    auth: false,
    headers: {
      "Content-Type": "multipart/form-data",
      'Accept': 'application/json'
    },
  });
}

}
