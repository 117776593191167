<template>
<div v-if="userLoggedIn">
  <MDBNavbar expand="lg" light bg="light" position="sticky" container>
    <MDBNavbarBrand href="/" title="Startseite"><img src="../assets/logo.webp" width="180" height="70" alt="Umzug Bayern" title="Umzug Bayern Logo" loading="lazy" class="d-inline-block align-middle mr-2"></MDBNavbarBrand>
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="me-lg-4 pe-4 mb-2 mb-lg-0 ms-auto mb-2 mb-lg-0">
        <MDBNavbarItem :to="{ name: 'dashboard' }" title="Aufträge Suchen" active>Aufträge Suchen</MDBNavbarItem>
        <MDBNavbarItem>
          <!-- Navbar dropdown -->
          <MDBDropdown class="nav-item" v-model="dropdown1">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown1 = !dropdown1"
              >{{userName}}</MDBDropdownToggle>
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem role="button" :to="{ name: 'profile' }" title="Profil">
                <i class="fas fa-user-alt"></i> Firmenprofil
              </MDBDropdownItem>
              <MDBDropdownItem role="button" :to="{ name: 'favorites' }" title="Favoriten">
                <i class="fas fa-star"></i> Favoriten
              </MDBDropdownItem>
              <MDBDropdownItem role="button" :to="{ name: 'calendar' }" title="Terminplaner">
                <i class="fas fa-calendar-alt"></i> Terminplaner
              </MDBDropdownItem>
              <MDBDropdownItem role="button" :to="{ name: 'invoices' }" title="Rechnungsverwalter">
                <i class="fas fa-file-invoice"></i> Rechnungsverwalter
              </MDBDropdownItem>
              <MDBDropdownItem role="button" :to="{ name: 'profile' }" title="Abonement">
                <i class="fas fa-file-signature"></i> Abonement
              </MDBDropdownItem>
              <MDBDropdownItem role="button" :to="{ name: 'settings' }" title="Einstellungen">
                <i class="fas fa-cog"></i> Einstellungen
              </MDBDropdownItem>
              <MDBDropdownItem href="#" v-on:click="onLogOut"><i class="fas fa-sign-out-alt"></i> Logout</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
</div>
</template>

<script>
  import {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';

  export default {
    components: {
      MDBNavbar,
      MDBNavbarToggler,
      MDBNavbarBrand,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdown,
      MDBDropdownToggle,
      MDBDropdownMenu,
      MDBDropdownItem
    },
    computed: {
      userLoggedIn() {
        return this.$store.state.user_authenticated
      },
      userVerified() {
        return this.$store.state.user_verified
      },
      userName() {
        return this.$store.state.user.name
      },
      currentRouteName() {
          return this.$route.name
      }
  },
  methods: {
    onLogOut() {
      this.$store.dispatch('logout')
    },
    OnlineFormClicked() {
      this.$gtag.event('OnlineForm', {
        'event_category' : 'Interaction',
        'event_label' : 'online form link clicked on top menu'
      })
    }
  },
  setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      const dropdown2 = ref(false);
      const dropdown3 = ref(false);
      const dropdown4 = ref(false);
      const dropdown5 = ref(false);
      return {
        collapse1,
        dropdown1,
        dropdown2,
        dropdown3,
        dropdown4,
        dropdown5
      }
    }
  };
</script>
