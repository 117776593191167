<!-- Please remove this file from your project -->
<template>
  <div class="reveal">
    <div class="row bg-white" no-gutters align-v="center" >
      <div class="px-4 py-4 col-md-8 align-self-center" v-if="textside=='left'">
        <slot></slot>
        <a v-if="link" :title="linktitle" class="btn btn-primary mt-4 text-uppercase" :href="link">
          {{ linktext }}
        </a>
      </div>
      <div class="col-md-4 p-0">
        <div class="limit">
            <img loading="lazy" :src="imgsrc" class="sideimage img" fluid :alt="imgalt" :title="imgalt"/>
        </div>
      </div>
      <div class="col px-4 py-4 col-md-8 align-self-center" v-if="textside=='right'">
        <slot></slot>
          <a v-if="link" :title="linktitle" class="btn btn-primary mt-4 text-uppercase" :href="link">
            {{ linktext }}
          </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPostPreview',
  props: {
    imgsrc: {
      type: String,
      default: require('@/assets/iStock-622277572.webp')
    },
    imgalt: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    strong1: {
      type: String,
      default: null
    },
    strong2: {
      type: String,
      default: null
    },
    strong3: {
      type: String,
      default: null
    },
    strong4: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    text2: {
      type: String,
      default: null
    },
    text3: {
      type: String,
      default: null
    },
    textside: {
      type: String,
      default: 'left'
    },
    link: {
      type: String,
      default: null
    },
    linktitle: {
      type: String,
      default: 'mehr erfahren'
    },
    linktext: {
      type: String,
      default: 'mehr erfahren'
    }
  },

  mounted () {
    window.addEventListener('scroll', this.reveal)
    this.reveal()
  },

  methods: {
    reveal: () => {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 150
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add('active')
        } else {
          reveals[i].classList.remove('active')
        }
      }
    }
  }
}
</script>

<style>
.reveal {
  position: relative;
  transform: translateY(0);
  opacity: 0;
  transition: 1s all ease;
}

.sideimage {

}

.sideimage:hover {

  transition: 1s;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.limit img{
   width: 100%;
   height: 100%;
 }
</style>
