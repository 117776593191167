<template>
<div class="bg-white py-2">
  <a href="/" class="navbar-logo mx-2" title="Startseite"><img src="../assets/logo.webp" width="180" height="70" alt="Umzug Bayern" title="Umzug Bayern Logo" loading="lazy" class="d-inline-block align-middle mr-2"></a>
  <nav class="nav">
    <input class="nav__trigger-input" type="checkbox" id="trigger" aria-label="open the navigation"/>
    <label class="nav__trigger-finger" for="trigger">
      <span></span>
    </label>
    <ul class="nav__list">
      <li class="nav__item">
      <a href="#" class="nav__link">
        <span class="nav__text">
          Leistungen
        </span>
      </a>
      <input class="nav__submenu-trigger-input" type="checkbox" id="submenu-trigger" />
      <label class="nav__submenu-trigger-finger" for="submenu-trigger"><span class="text-primary">///</span></label>
        <ul class="nav__list-child">
          <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'unser-angebot' }" title="Unser Angebot">Unser Angebot</router-link></li>
          <li class="nav__item">
              <a href="#" class="text-white">Umzug</a>
              <ul class="sub-menu">
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'umzug-planen' }" title="Umzug Planen">Umzug Planen
                </router-link ></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'umzugsfirma' }" title="Umzugsfirma">Umzugsfirma
                </router-link ></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'umzug-muenchen-berlin' }" title="Umzug Berlin München">Umzug Berlin München
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'privatumzuege' }" title="Privatumzüge">Privatumzüge
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'seniorenumzuege' }" title="Seniorenumzüge">Seniorenumzüge
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'studentenumzug' }" title="Studentenumzug">Studentenumzüge
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'firmenumzuege' }" title="Firmenumzüge"> Firmenumzüge
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'moebelmontage' }" title="Möbelmontage"> Möbelmontage
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'blog' }" title="Umzugsratgeber"> Umzugsratgeber
                </router-link></li>
              </ul>
          </li>
          <li class="nav__item">
              <a href="#" class="text-white">Entrümpelung</a>
              <ul class="sub-menu">
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'entruempelung' }" title="Entrümpelung">Entrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'hausentruempelung' }" title="Hazsentrümpelung">Hausentrümpelungen
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'wohnungsentruempelung' }" title="Wohnungsentrümpelung">Wohnungsentrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'entkernung' }" title="Entkernung">Entkernung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'kellerentruempelung' }" title="Kellerentrümpelung">Kellerentrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'garagenentruempelung' }" title="Garagenentrümpelung">Garagenentrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'dachbodenentruempelung' }" title="Dachbodenentrümpelung">Dachbodenentrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'lagerentruempelung' }" title="Lagerentrümpelung">Lagerentrümpelung
                </router-link></li>
                <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'firmenentruempelung' }" title="Firmenentrümpelung">Firmenentrümpelung
                </router-link></li>
              </ul>
          </li>
          <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'tatortreinigung' }" title="Tatortreinigung">Tatortreinigung</router-link></li>
          <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'messeaufbau' }" title="Messe Transport und Aufbau">Fachmessen</router-link></li>
        </ul>
      </li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'umzugsmaterialien' }" title="Umzugsmaterial" >Umzugsmaterial</router-link></li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'faq' }" title="FAQ" >FAQ</router-link></li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'preise' }" title="Preise" >Preise</router-link></li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'bewertungen' }" title="Bewertungen" >Bewertungen</router-link></li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'blog' }" title="Umzugsratgeber" >Umzugsratgeber</router-link></li>
      <li class="nav__item"><router-link class="nav__link" @click="closemenu" :to="{ name: 'kontakt' }" title="Kontakt" >Kontakt</router-link></li>
      <li class="nav__item"><router-link class="" @click="OnlineFormClicked" title="Angebot einholen" :to="{ name: 'requestform' }" >
        <div title="Angebot einholen" alt="Angebot einholen" class="btn btn-outline-white btn-lg">
        Angebot einholen
        </div>
      </router-link></li>
    </ul>
  </nav>
</div>
</template>

<script>
/* eslint-disable */
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    Name: "NavbarMobileCSSOnly",
    components: {

    },
    computed: {
      userLoggedIn() {
        return this.$store.state.user_authenticated
      },
      userVerified() {
        return this.$store.state.user_verified
      },
      userName() {
        return this.$store.state.user.name
      },
      currentRouteName() {
          return this.$route.name
      },
      currentRouteName() {
        return useRoute().name;
      }
  },
  methods: {
    closemenu(){
      document.getElementById("trigger").checked = false;
    },
    OnlineFormClicked() {
      this.$gtag.event('OnlineForm', {
        'event_category' : 'Interaction',
        'event_label' : 'online form link clicked on top menu'
      })
      this.closemenu()
    }
  },
  setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      const dropdown2 = ref(false);
      const dropdown3 = ref(false);
      const dropdown4 = ref(false);
      const dropdown5 = ref(false);
      return {
        collapse1,
        dropdown1,
        dropdown2,
        dropdown3,
        dropdown4,
        dropdown5
      }
    }
  };
</script>

<style scoped lang="scss">
$bezier: cubic-bezier(0.75, 0, 0.25, 1);
$primary: #246f80;
$secondary: #3fb2cd;
$white: #ffffff;
$gray: #757575;
$menuHeight      : 3rem;
$subHeight       : 2rem;
$subwidth        : 200px;
$subPadding      : 0.35rem 0.25rem 0.25rem;
$suboffset       : 0.35rem; /* offset the top padding */
$navbg : #ffffff;
$topcolor : $primary;
$topactivecolor : $secondary;
$subbg : $primary;
$sublink : $white;
$sublinkactivebg : #000000;
$nestedbg : $primary;
$currentcolor : $topcolor;

* {
  margin: 0;
  box-sizing: border-box;
  font-family: HelveticaNeue, Helvetica, sans-serif;
}

.nav {
  color: white;
  &__trigger-finger {
    // The visible part of the trigger, this will be your "nav is closed!"-state
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 15px;
    height: 10px;
    margin: 40px;
    color: $primary;
    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      bottom: -5px;
      right: -5px;
      background: $primary;
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s $bezier;
    }
    span {
      display: block;
      width: 100%;
      height: 2px;
      transition: transform 1s $bezier;
      background: currentColor;
      &::before,
      &::after {
        // Create the hamburger lines
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: block; // Labels are inline elements, it needs a display property that allows it it's own set height like block;
        height: 2px;
        background: currentColor; // inherits color set on parent
        transition: transform 0.4s $bezier, top 0.4s 0.4s $bezier, background 0.2s $bezier;
      }
      &::before {
        top: 0;
      }
      &::after {
        top: calc(100% - 2px);
      }
    }
  }
  &__trigger-input, &__submenu-trigger-input {
    // We  want to make the input invisible without using display: none;, because that will remove functionality.
    opacity: 0;
    width: 0;
    height: 0;
    appearance: none;
    position: fixed;
  }
  &__trigger-input {
    &:focus {
      & + label {
        color: white;
        &::before {
          opacity: 1;
        }
      }
    }
    &:checked {
      & + label {
        // change the label if the input is checked, this will be your "nav is open! 🎉"-state
        height: 10px;
        color: white;
        span {
          transform: rotate(45deg);
          &::before, &::after {
            top: calc(50% - 1px);
            transition: transform 0.4s 0.4s $bezier, top 0.4s $bezier, background 0.2s $bezier;
          }
          &::after {
            transform: rotate(90deg);
          }
        }
      }
      & ~ ul {
        // We use a general sibling because it's less work, + label + ul would work as well
        z-index: 9;
        transform: none;
        transition: 0.5s $bezier;
      }
    }
  }
  &__list {
    // we want to make the top navigation items not visible while the input is not :checked.
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: $primary;
    /*font-size: 4rem;*/

    padding: 40px;
    transform: translate(100vw) rotate(45deg);
    transition: 0.2s $bezier;
  }
  &__item {
    list-style: none;
    & + & {
      margin-top: 20px;
    }
  }
  &__submenu-trigger-input {
    &:checked {
      & + label::after {
        transform: translateY(10px);
      }
      & ~ ul {
        display: block;
      }
    }
    &:focus {
      & + label::after {
        transform: translateY(5px);
      }
    }
  }
  &__submenu-trigger-finger {
    &::after {
      content: '';
      display: inline-block;
      border: 10px solid transparent;
      border-top-color: white;
      transition: 0.4s $bezier;
    }
  }
  &__link {
    text-decoration: none;
    color: inherit;
  }
  &__list-child {
    display: none;
    /*font-size: 2rem;*/
    margin-top: 10px;
    padding-left: 2rem;
  }
}


</style>
