<template lang="html">
  <div class="" style="margin-bottom: -10vh;">
    <div class="home-row-1 row" :style="'background-image: url(' + bg_image + ');margin:0px;'">
      <div class="align-self-center heading-stripe-bg" >
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 mt-4 text-left ">
              <div class=" p-4">
                <p class="h5"><span class="text-secondary">Umzug</span> <span class="text-primary">Bayern</span></p>
                <h1 v-if="heading" class="text-primary"><strong>{{ title1 }}<span class="text-secondary">{{ title2 }}</span></strong></h1>
                <p v-else class="text-primary h1"><strong>{{ title1 }}<span class="text-secondary">{{ title2 }}</span></strong></p>
                <p class="text-black"><strong>{{ subtitle }}</strong></p>
                <p> <span>
                  <router-link @click="Button1Clicked" :title="button1_title" :to="{ name: button1_route }" class="mt-2 btn btn-primary btn-lg"><MDBIcon :icon="button1_icon" class="me-2" size="1x" />{{ button1_anchor }}</router-link>
                  <MDBBtn class="mt-2" tag="a" :href="button2_route" :title="button2_title" @click="Button2Clicked" color="dark" size="lg"><MDBIcon icon="phone" class="me-2" size="1x" />{{ button2_anchor }}</MDBBtn></span> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 p-4 align-self-center">

      </div>
    </div>
  </div>
</template>

<script>
import { MDBBtn, MDBIcon } from 'mdb-vue-ui-kit'

export default {
  name: 'HeadImage',
  components: {
    MDBBtn, MDBIcon
  },
  props: {
    heading: {
      type: Boolean,
      default: true
    },
    title1 : {
      type: String,
      default: 'Ihr professionelles '
    },
    title2 : {
      type: String,
      default: 'Umzugsunternehmen aus München'
    },
    subtitle: {
      type: String,
      default: 'Professioneller Umzugsservice mit Kompetenz und Erfahrung für Privat und Gewerbe.'
    },
    button1_anchor: {
      type: String,
      default: 'Angebot anfragen'
    },
    button1_route: {
      type: String,
      default: 'requestform'
    },
    button1_title: {
      type: String,
      default: 'Angebot einholen'
    },
    button1_icon: {
      type: String,
      default: 'clipboard-list'
    },
    button2_anchor: {
      type: String,
      default: '015730438580'
    },
    button2_route: {
      type: String,
      default: 'tel:+4915730438580'
    },
    button2_title: {
      type: String,
      default: 'Umzug Bayern Anrufen'
    },
    button2_icon: {
      type: String,
      default: 'phone'
    },
    bg_image: {
      type: String,
      default: require('@/assets/slide1_light.webp')
    }
  },
  methods: {
    Button1Clicked: function () {
      this.$emit('Button1Clicked')
    },
    Button2Clicked: function () {
      this.$emit('Button2Clicked')
    }
  }
}
</script>

<style lang="css" scoped>
.home-row-1 {
  background-position: top center;
	background-repeat: no-repeat;
	background-size:cover;
	background-attachment:fixed;
  min-height: 50vh;

}

.heading-stripe-bg{
  background-color: rgba(255,255,255,0.6);
}
</style>
