//
import axios from 'axios'

import { event } from "vue-gtag-next"

export default class BaseApi {

  ApiCallGetMethod(route)
  {
    return new Promise((resolve, reject) => {
      /*if(route.auth == true)
      {
        if(store.state.user && store.state.user_authenticated)
        {
          route.headers.Authorization= 'Bearer ' + store.state.user.token
        } else {
          reject(new Error('user not authorized'))
        }
      }*/
      axios.get(route.url, { headers: route.headers }).then((data) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    }).catch((error) => {
      throw error
    })
  }

  ApiCallPostMethod(route)
  {
    return new Promise((resolve, reject) => {
      /*if(route.auth == true)
      {
        if(store.state.user && store.state.user_authenticated)
        {
          route.headers.Authorization= 'Bearer ' + store.state.user.token

        } else {
          reject(new Error('user not authorized'))
        }
      }*/
      axios.post(
        route.url,
        route.data, {
          headers: route.headers
        }
      ).then((data) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    }).catch((error) => {
      throw error
    })
  }

  ApiCall(route)
  {
    //console.log(route.url);
    //console.log("%o", route);
    return new Promise(( resolve, reject) => {
      if(route == undefined)
      {
        reject(new Error('route undefined'))
      }
      if(route.method == undefined)
      {
        reject(new Error('method undefined'))
      }
      //console.log(route);

      for (var id in route.paramnames) {
        var parname = route.paramnames[id]
        var paramvalue = route.paramvalues[id]
        route.url = route.url.replace("{" + parname + "}", paramvalue)
      }

      switch (route.method) {
        case "GET":
        //console.log("%o", route);
          this.ApiCallGetMethod(route).then((data) => {
            event(route.url, {
              'event_category' : 'API_success',
              'event_label' : 'GET: ' + route.url + ' -> ' + 200
            })
            resolve(data.data)
          }).catch((error) => {
            reject(error)
          })
        break;
        case "POST":
          this.ApiCallPostMethod(route).then((data) => {
            event(route.url, {
              'event_category' : 'API_success',
              'event_label' : 'POST: ' + route.url + ' -> ' + 200
            })
            resolve(data.data)
          }).catch((error) => {
            reject(error)
          })
        break;
        default:
        reject(new Error('method unkown'))
      }
    }).catch((error) => {
      if(error.response !== undefined)
      {
        event(route.url, {
          'event_category' : 'API_Error',
          'event_label' : route.url + ' -> ' + error.response.status
        })
      }
      if(error.response.status == 403) {
        // console.log(error.response.status);
        //store.dispatch('logout', router.currentRoute._rawValue)
      } else {
        throw error
      }
    })
  }
}
