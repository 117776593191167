<template>
  <div class="">
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
    <div class="d-none d-lg-block d-xl-block bg-dark">
      <div class="container text-center">
        <small class="a sr-16px sr-circle sr-opacity sr-icon-dark sr-bdr-s sr-bg-white sr-pad">
          <small class="text-primary sr-facebook"><a href="https://www.facebook.com/umzug.bayern.online" target="_blank" title="Umzug Bayern auf Facebook"><i class="fab fa-facebook-f"></i></a></small>
          <small class="sr-instagram"><a href="https://www.instagram.com/umzugbayern/" target="_blank" title="Umzug Bayern auf Instagram"><i class="fab fa-instagram"></i></a></small>
          <small class="sr-youtube"><a href="https://www.youtube.com/channel/UCCasjVt8K-Arx5hyuhSCFQg" target="_blank" title="Umzug Bayern auf Youtube"><i class="fab fa-youtube"></i></a></small>
          <small class="sr-whatsapp"><a href="https://api.whatsapp.com/send?phone=+4915730438580" target="_blank" title="Umzug Bayern auf WhatsApp"><i class="fab fa-whatsapp"></i></a></small>
          <small class="sr-email"><a href="mailto:info@umzug-bayern.com" target="_blank" title="Umzug Bayern per E-Mail kontaktieren"><i class="fa fa-envelope"></i></a></small>
          <small class="text-secondary ms-4">
            <MDBIcon v-once icon="clock" class="ms-3 me-2 text-secondary"/><small class="text-light">Mo - So 07:00 Uhr - 20:00 Uhr</small>
            <a title="Adresse von Umzug Bayern" class="text-light" href="https://goo.gl/maps/fFSb5vWiAgSXLccA6"><MDBIcon v-once icon="map-marker-alt" class="ms-3 me-2 text-secondary"/>Poststr. 1, 85586 Poing</a>
            <a class="text-light" href="tel:004915730438580" title="Umzug Bayern Anrufen"><MDBIcon v-once icon="phone" class="ms-3 me-2 text-secondary"/>+49 15730438580</a>
            <a class="text-light" href="mailto:info@umzug-bayern.com" title="Umzug Bayern per E-Mail kontaktieren"><MDBIcon v-once icon="envelope" class="ms-3 me-2 text-secondary"/>info@umzug-bayern.com</a></small>
        </small>
      </div>
    </div>
    <NavbarAdmin v-if="userLoggedIn" />
    <NavbarMobileCSSOnly class="d-lg-none" />
    <NavbarCSSOnly class="d-none d-lg-block py-1" />
    <div class="min-vh-100">
      <router-view/>
    </div>
    <CookieConsentBanner/>
    <AnfrageButton v-if="currentRouteName !='requestform'" />
    <div class="bg-black py-3 text-light shadow-sm">
      <div class="container">
        <div class="row">
          <div class="col">
            <p>Unsere Dienstleistungen erstrecken sich über einen regionalen und bayernweiten Kreis.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4 col-12">
            <ul class="list-unstyled">
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung München" :to="{ name: 'entruempelung_in_muenchen' }">Entrümpelung München</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Starnberg" :to="{ name: 'entruempelung_in_starnberg' }">Entrümpelung Starnberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Poing" :to="{ name: 'entruempelung_in_poing' }">Entrümpelung Poing</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Augsburg" :to="{ name: 'entruempelung_in_augsburg' }">Entrümpelung Augsburg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Bamberg" :to="{ name: 'entruempelung_in_bamberg' }">Entrümpelung Bamberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Erding" :to="{ name: 'entruempelung_in_erding' }">Entrümpelung Erding</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Freising" :to="{ name: 'entruempelung_in_freising' }">Entrümpelung Freising</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Ingolstadt" :to="{ name: 'entruempelung_in_ingolstadt' }">Entrümpelung Ingolstadt</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Landshut" :to="{ name: 'entruempelung_in_landshut' }">Entrümpelung Landshut</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Nürnberg" :to="{ name: 'entruempelung_in_nuernberg' }">Entrümpelung Nürnberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Rosenheim" :to="{ name: 'entruempelung_in_rosenheim' }">Entrümpelung Rosenheim</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Straubing" :to="{ name: 'entruempelung_in_straubing' }">Entrümpelung Straubing</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Tegernsee" :to="{ name: 'entruempelung_in_tegernsee' }">Entrümpelung Tegernsee</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Entrümpelung Würzburg" :to="{ name: 'entruempelung_in_wuerzburg' }">Entrümpelung Würzburg</router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-4 col-12">
            <ul class="list-unstyled">
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug München" :to="{ name: 'umzug_in_muenchen' }">Umzug München</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Poing" :to="{ name: 'umzug_in_poing' }">Umzug Poing</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Augsburg" :to="{ name: 'umzug_in_augsburg' }">Umzug Augsburg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Bamberg" :to="{ name: 'umzug_in_bamberg' }">Umzug Bamberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Erding" :to="{ name: 'umzug_in_erding' }">Umzug Erding</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Freising" :to="{ name: 'umzug_in_freising' }">Umzug Freising</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Ingolstadt" :to="{ name: 'umzug_in_ingolstadt' }">Umzug Ingolstadt</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Landshut" :to="{ name: 'umzug_in_landshut' }">Umzug Landshut</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Nürnberg" :to="{ name: 'umzug_in_nuernberg' }">Umzug Nürnberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Rosenheim" :to="{ name: 'umzug_in_rosenheim' }">Umzug Rosenheim</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Straubing" :to="{ name: 'umzug_in_straubing' }">Umzug Straubing</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Tegernsee" :to="{ name: 'umzug_in_tegernsee' }">Umzug Tegernsee</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Umzug Würzburg" :to="{ name: 'umzug_in_wuerzburg' }">Umzug Würzburg</router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-4 col-12">
            <ul class="list-unstyled">
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder München" :to="{ name: 'halteverbotsschilder_in_muenchen' }">Halteverbotsschilder München</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Augsburg" :to="{ name: 'halteverbotsschilder_in_augsburg' }">Halteverbotsschilder Augsburg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Bamberg" :to="{ name: 'halteverbotsschilder_in_bamberg' }">Halteverbotsschilder Bamberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Erding" :to="{ name: 'halteverbotsschilder_in_erding' }">Halteverbotsschilder Erding</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Freising" :to="{ name: 'halteverbotsschilder_in_freising' }">Halteverbotsschilder Freising</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Ingolstadt" :to="{ name: 'halteverbotsschilder_in_ingolstadt' }">Halteverbotsschilder Ingolstadt</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Landshut" :to="{ name: 'halteverbotsschilder_in_landshut' }">Halteverbotsschilder Landshut</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Nürnberg" :to="{ name: 'halteverbotsschilder_in_nuernberg' }">Halteverbotsschilder Nürnberg</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Rosenheim" :to="{ name: 'halteverbotsschilder_in_rosenheim' }">Halteverbotsschilder Rosenheim</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Straubing" :to="{ name: 'halteverbotsschilder_in_straubing' }">Halteverbotsschilder Straubing</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Tegernsee" :to="{ name: 'halteverbotsschilder_in_tegernsee' }">Halteverbotsschilder Tegernsee</router-link>
              </li>
              <li>
                <MDBIcon v-once icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/>
                <router-link v-once class="text-secondary" title="Halteverbotsschilder Würzburg" :to="{ name: 'halteverbotsschilder_in_wuerzburg' }">Halteverbotsschilder Würzburg</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-s-12 text-left">
            <p class="h5" >So erreichen Sie uns</p>
            <p> <MDBIcon v-once icon="map-marker-alt" class="me-3 text-secondary"/><a class="text-secondary" href="https://goo.gl/maps/fFSb5vWiAgSXLccA6">Poststr. 1, 85586 Poing</a></p>
            <p><MDBIcon v-once icon="phone" class="me-3 text-secondary"/><a class="text-secondary" href="tel:004915730438580" title="Anrufen">+49 15730438580</a></p>
            <p><MDBIcon v-once icon="envelope" class="me-3 text-secondary"/><a class="text-secondary" href="mailto:info@umzug-bayern.com" title="E-Mail">info@umzug-bayern.com</a></p>
            <div class="socializer a sr-32px sr-circle sr-opacity sr-icon-dark sr-bdr-md sr-bg-white sr-pad">
              <span class="sr-facebook"><a aria-label="Besuche Umzug Bayern auf Facebook" href="https://www.facebook.com/umzug.bayern.online" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></span>
              <span class="sr-instagram"><a aria-label="Besuche Umzug Bayern auf Instagram" href="https://www.instagram.com/umzugbayern/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></span>
              <span class="sr-youtube"><a aria-label="Besuche Umzug Bayern auf Youtube" href="https://www.youtube.com/channel/UCCasjVt8K-Arx5hyuhSCFQg" target="_blank" title="Youtube"><i class="fab fa-youtube"></i></a></span>
              <span class="sr-whatsapp"><a aria-label="Schreibe Umzug Bayern auf WhatsApp" href="https://api.whatsapp.com/send?phone=+4915730438580" target="_blank" title="WhatsApp"><i class="fab fa-whatsapp"></i></a></span>
              <span class="sr-email"><a aria-label="Schreibe Umzug Bayern per E-Mail" href="mailto:info@umzug-bayern.com" target="_blank" title="E-Mail"><i class="fa fa-envelope"></i></a></span>
            </div>
          </div>
          <div class="col-sm-4 col-s-12 text-left">
            <p><router-link v-once class="text-secondary" :to="{ name: 'blog' }" title="Umzugsratgeber">Umzugsratgeber</router-link></p>
            <p><router-link v-once class="text-secondary" :to="{ name: 'about' }" title="Über Umzug Bayern">Über Uns</router-link></p>
            <p><router-link rel="nofollow,noindex" v-once class="text-secondary" :to="{ name: 'agb' }" title="Allgemeine Geschäftsbedingungen">AGB</router-link></p>
            <p><router-link rel="nofollow,noindex" v-once class="text-secondary" :to="{ name: 'datenschutz' }" title="Datenschutz">Datenschutz</router-link></p>
            <p><router-link rel="nofollow,noindex" v-once class="text-secondary" :to="{ name: 'impressum' }" title="Impressum">Impressum</router-link></p>
            <p><router-link v-once class="text-secondary" :to="{ name: 'kontakt' }" title="Kontakt">Kontakt</router-link></p>
          </div>
          <div class="col-sm-4 col-s-12 text-center">
            <div class="mt-2">
              <a href="https://g.page/umzug-bayern/review?rc"><img style="max-height:80px" :src="require('@/assets/umzug-bayern-google-rating.webp')" alt="Umzug Bayern bei Google bewerten"></a>
            </div>

            <div class="mt-2">
              <div class="trustpilot-widget" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="5ed6277829b9d400013f9aaf" data-style-height="52px" data-style-width="100%">
              <a href="https://de.trustpilot.com/review/www.umzug-bayern.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
          </div>
          <div class="mt-2">
            <a  href="https://umzug.check24.de/umzug/profil/raopam" target="_blank" title="zum CHECK24 Profi Profil"><img src="https://cdn.profis.check24.de/widget/2019.svg" alt="CHECK24 Profi Siegel" height="111" width="128" /></a>
          </div>
          </div>
          </div>
        <div class="row mb-6">
          <div class="col-12 text-center">
            <small class="text-light">Umzug-Bayern {{ new Date().getFullYear() }} | Bereitgestellt von <a class="text-secondary" href="https://alpisoft.net" title="Softwareentwicklung von alpisoft.net">alpisoft.net</a></small>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
$border-radius-base: 4px;
$border-radius: $border-radius-base;
$carousel-caption-spacer: 30% !default;
$carousel-caption-width: 100% !default;
$link-color:                 #246f80 !default; // primary
$link-hover-color:           #3fb2cd !default; // secondary
@import '~@/../mdb/scss/index.free.scss';
@import '~@/../socializer/socializer.min.css';
@import '~@/../data/_colors.scss';

.alignleft {
  float:left;
  margin-right: 1em;
}
.alignright {
  float:right;
  margin-left: 1em;
}
.aligncenter {
  display: block;
  margin: auto;
  margin-right: 1em;
  margin-left: 1em;
}

.wp-block-image figure {
  display: table;
}
.wp-block-image figcaption {
  display: table-caption;
  caption-side: bottom;
  margin-top: 0.5em;
    margin-bottom: 1em;
}


#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  background-color: #e3e3e3
}

</style>

<script>
// @ is an alias to /src
import NavbarCSSOnly from '@/components/NavbarCSSOnly.vue'
import NavbarAdmin from '@/components/NavbarAdmin.vue'
import NavbarMobileCSSOnly from '@/components/NavbarMobileCSSOnly.vue'
import CookieConsentBanner from '@/components/dataprotection/CookieConsentBanner.vue'
import AnfrageButton from '@/components/AnfrageButton.vue'
import { MDBIcon } from "mdb-vue-ui-kit"
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  components: {
    NavbarAdmin,
    NavbarCSSOnly,
    NavbarMobileCSSOnly,
    CookieConsentBanner,
    AnfrageButton,
    MDBIcon
  },
  computed: {
    currentRouteName() {
      return useRoute().name;
    },
    userLoggedIn() {
      return this.$store.state.user_authenticated
    }
  },
  methods: {
    GoogleFeedbackClicked() {
      this.$gtag.event('GoogleFeedback', {
        'event_category' : 'Interaction',
        'event_label' : 'google feedback link clicked on footer'
      })
    },
    PartnerClicked() {
      this.$gtag.event('Partner', {
        'event_category' : 'Interaction',
        'event_label' : 'partner link clicked on footer'
      })
    }
  },
  setup() {
    const title = "Umzug | umzug-bayern.com"
    const description = "Alles rund um den Umzug. Umzug in München und ganz Bayern zu günstigen Preisen. Individueller Service."
    const url = process.env.VUE_APP_URL
    useMeta({
      title: title,
      description: description,
      keywords: "Umzugsunternehmen, Umzugsangebote, Umzugshelfer, Umziehen, Umzugstipps, Umzugsmaterialien, Umzugskostenrechner",
      link: {
        rel: "canonical",
        href: url
      },
      og: {
        type: "website",
        title: title,
        url: url,
        description: description,
        site_name: "umzug-bayern",
        image: process.env.VUE_APP_URL + require("@/assets/logo.webp")
      },
      twitter: {
        card: "summary",
        title: title,
        url: url,
        description: description,
        label1: "Geschätzte Lesezeit",
        data1: "5 Minuten"
      },
      robots: "index, follow"
    })

  }
}
</script>
