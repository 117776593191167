<template>
  <div class="container">
    <div class="row align-center">
      <div class="col-1 align-self-center text-center text-primary">
        <MDBIcon :onclick="leftClick" type="button" icon="chevron-left" iconStyle="fas" size="1x" />
      </div>
      <div class="col-lg-5 col-10 revealreview active">
        <Review v-if="selectedReview" :review="selectedReview" />
      </div>
      <div class="col-lg-5 revealreview active d-none d-lg-block">
        <Review v-if="selectedReviewSecond" :review="selectedReviewSecond" />
      </div>
      <div class="col-1 align-self-center text-center text-primary">
        <MDBIcon :onclick="rightClick" type="button" icon="chevron-right" iconStyle="fas" size="1x" />
      </div>
    </div>
    <div class="row mt-2 mb-2" hidden>
      <div class="col align-self-center text-center text-primary">
        <span class="me-2" v-for="index in reviews.length" :key="index">
          <MDBIcon  type="button" icon="circle" iconStyle="fas" size="1x" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Review from '@/components/Review.vue'
import { MDBIcon } from 'mdb-vue-ui-kit'

export default {
  name: 'ReviewsCarousel',

  components: {
    MDBIcon,
    Review
  },
  data() {
    return {
      numOfVisibleReviews: 1,
      selectedReviewIndex: 0,
      reviews: [
        {
          rev_name: "Arielle Arabie",
          rev_text:"Ich bin wie immer sehr zufrieden. Ich 6 mal mit der Firma Umzug Bayern und Diez Umzug, umgezogen. Ich kann nur sagen... top! Ich kann nur weiterempfehlen. Liebe Grüße aus Aschau Arielle",
          rev_link: "https://g.co/kgs/YxHBkn",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Julia",
          rev_text:"Die Firma Umzug Bayern hat den Umzug meiner Eltern - Ü 60 durchgeführt. Das war für meine Eltern eine stressige Situation, aber die Firma Umzug Bayern hat den Umzug sehr professionell gemeistert. Die Mitarbeiter haben vorrausschauend und durchdacht gearbeitet. Es ging alles zügig voran. Sie haben meinen Eltern sehr geholfen den Umzug stressfrei zu erleben. Es wurde alles sorgsam für den Transport verpackt, der Boden mit Vlies geschützt. Die Möbeln wurden flexibel nach Wunsch meiner Eltern montiert. Ähnelt einer Schreinerleistung. Auch die Lampen, Spiegeln und Bilder haben sie angebracht. Bei der Aufgabe sind die Arbeiter sehr geduldig und freundlich geblieben. Man merkt dieser Umzugsfirma die lange Erfahrung an. Ich kann die Firma Umzug Bayern - Alexander Maier Kleintransporte wärmstens weiter empfehlen! Vielen Dank noch mal.",
          rev_link: "https://g.co/kgs/WnQy7G",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Ksali Musik",
          rev_text:"Umzug innerhalb München. Beratung  Professionalität. Möbel wurde Fachgerecht eingepackt und Transportiert. Es wurden keine Schäden verursacht.  Nette Mitarbeiter,  würde ich weiter empfehlen.",
          rev_link: "https://g.co/kgs/hof6FN",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Wolfgang Hofstaller",
          rev_text:"Super die Jungs.  Pünktlich,  schnell  sehr professionell.  Unser Umzug Anfang des Jahres hat super geklappt. Auch der Preis passt. Absolut empfehlenswert.",
          rev_link: "https://g.co/kgs/LRmQ9y",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "GEHI 7",
          rev_text:"Umzug innerhalb München inkl. Halteverbotszone Syper Team, kann nur weiter empfehlen.",
          rev_link: "https://g.co/kgs/2HNSBv",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Markus Hemm",
          rev_text: "Wir haben nun schon zum zweiten Mal unseren Umzug mit Umzug Bayern gemacht und können den Service und das Unternehmen uneingeschränkt empfehlen. Herr Maier und sein Team haben von der Auftragsannahme bis hin zur Auftragsabwicklung einen grandiosen und professionellen Job gemacht. Besonders hervorheben möchte ich Max und Ivan, die jeder Herausforderung gewachsen waren. Wir werden den Service gerne wieder wählen.",
          rev_link: "https://goo.gl/maps/VewuzK9UtwRWgJXT6",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Regina S.",
          rev_text: "Wir sind sehr zufrieden mit unserem Umzug und der ganzen Organisation. Schnelle Kommunikation, Freundlichkeit und super Preis-Leistung! Alle aus dem Team haben einen tollen und schnellen Job gemacht. Danke dafür! Es ist nichts kaputt gegangen und das Team ging mit viel Erfahrung und Vorsicht bei brüchigen Dingen ans Werk. Wir würden uns jederzeit wieder für dieses Team entscheiden.",
          rev_link: "https://de.trustpilot.com/review/www.umzug-bayern.com",
          rev_rating: 5,
          rev_source: "Trustpilot Bewertung"
        },
        {
          rev_name: "Natalia König",
          rev_text: "Ich war sehr zufrieden! Schnelle und unkomplizierte Kommunikation. Der Umzug konnte schon eine Woche nach dem ersten Telefonat umgesetzt werden. Alle Möbel wurden sorgsam eingepackt und aus dem 5.OG (ohne Aufzug) in die neue Wohnung, wieder 5.OG (ohne Aufzug) getragen. Alle blieben trotzdem gut gelaunt, entspannt und gingen möglichst effizient vor. Ich würde ,,Transporte Maier,, jederzeit wieder buchen!",
          rev_link: "https://goo.gl/maps/gTxVq2hDcjnx6tUd8",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Daniela Rappl",
          rev_text: "Ich kann Alexander Maier und sein Team zu 100% weiter empfehlen, denn heutzutage findet man leider viel zu selten derartige professionelle Unternehmen deren Leitspruch fair, pünktlich, preislich top, vertrauensvoll, freundlich, fleissig, sauber, ehrlich lautet! Die Firma Umzug Bayern erfüllt ALLE vorgenannten Eigenschaften bzw. Versprechen. Ich bin wirklich sehr dankbar, diese Firma beauftragt zu haben und würde es bei Bedarf jederzeit wieder tun. Vom ganzem Herzen Dankeschön!!!",
          rev_link: "https://goo.gl/maps/GAbbDAv9zcmEXEyz6",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Martin E.",
          rev_text: "Danke an Herrn Maier und sein Team für den tollen und stressfreien Umzug von Bayern in den hohen Norden! Super Service, schneller Besichtigungstermin, direkt danach ein Kostenvoranschlag zu einem guten Preis. Am Umzugstag pünktliche, ordentliche und extrem schnelle Arbeit. Sehr höfliche und kompetente Mitarbeiter. Der Möbelab- und Aufbau war super, meine Möbel und Kisten wurden sehr sorgfältig behandelt und es kam zu keinem Schaden! So muss ein gutes Umzugsunternehmen arbeiten. Eine ganz klare Empfehlung von mir!",
          rev_link: "https://goo.gl/maps/kog9e59cNYrqF6zR9",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Tania Torres",
          rev_text: "Kann man nur weiter empfehlen. Danke für diesen tollen Service. Sie waren super,mussten jedesmal in den 3stock steigen und wieder runter...unglaublich wie sie trotz allem so freundlich gelächelt haben.Wirsind sehr froh das wir so glück hatten. DANKE NOCHMAL nallen 4 Männer. Hut ab!",
          rev_link: "https://goo.gl/maps/HqxLt3k2AsgS7Xtf6",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Stephan",
          rev_text: "Wir haben Umzug Bayern über die App im Playstore gefunden und konnten die Firma direkt per Smartphone beauftragen. Das Beste: Es gab direkt ein Angebot mit Preisangabe vor der Beauftragung. Vielen Dank für die schnelle Hilfe!!!",
          rev_link: "https://de.trustpilot.com/review/www.umzug-bayern.com",
          rev_rating: 5,
          rev_source: "Trustpilot Bewertung"
        },
        {
          rev_name: "Matthias Hommer",
          rev_text: "Ich habe Umzug Bayern für mehrere Aktivitäten genutzt: Umzug nach Österreich, Umzug innerhalb Münchens und Sperrmüllentsorgung. Ich war sehr zufrieden. Das Team ist sehr professionell, pünktlich, arbeitet zügig, transportiert auch schwere Möbel durch steile und enge Räume und baut auch hochwertige Möbel passgenau ab und wieder auf. Interaktion, Angebotserstellung und Terminfindung geht sehr schnell und die finalen Kosten entsprachen auch dem Angebotspreis. Ich kann Umzug Bayern daher absolut weiterempfehlen.",
          rev_link: "https://goo.gl/maps/CKQiZiiwLrst9wPh8",
          rev_rating: 5,
          rev_source: "Google Bewertung"
        },
        {
          rev_name: "Augustus R.",
          rev_text: "Ich war sehr zufrieden mit dem Umzugsunternehmen. Alles bestens. Sehr gutes Preis-Leistungsverhältnis.",
          rev_link: "https://de.trustpilot.com/review/www.umzug-bayern.com",
          rev_rating: 5,
          rev_source: "Trustpilot Bewertung"
        },
      ]
    };
  },
  computed: {
    numOfDots: function() {
      if(this.reviews.length > 0) {
        return this.reviews.length / this.numOfVisibleReviews
      }
      else {
        return 1
      }
    },
    selectedReview: function () {
      return this.reviews[this.selectedReviewIndex]
    },
    selectedReviewSecond: function () {
      var index = this.selectedReviewIndex
      if(index < (this.reviews.length - 1)) {
        index = index + 1
      } else {
        index = 0
      }
      return this.reviews[index]
    }
  },
  methods: {
    dotClick: function(index) {
      this.selectedReviewIndex = index
    },
    leftClick: function() {
      this.hide()
      setTimeout(() => {
        if(this.selectedReviewIndex > 0) {
          this.selectedReviewIndex = this.selectedReviewIndex - 1
        } else {
          this.selectedReviewIndex = this.reviews.length - 1
        }
      }, 500)
      setTimeout(() => {
        this.reveal()
      }, 500)
    },
    rightClick: function() {
      this.hide()
      setTimeout(() => {
        if(this.selectedReviewIndex < (this.reviews.length - 1)) {
          this.selectedReviewIndex = this.selectedReviewIndex + 1
        } else {
          this.selectedReviewIndex = 0
        }
      }, 500)
      setTimeout(() => {
        this.reveal()
      }, 500)
    },
    reveal: () => {
      const reveals = document.querySelectorAll('.revealreview')
      for (let i = 0; i < reveals.length; i++) {
          reveals[i].classList.add('active')
      }
    },
    hide: () => {
      const reveals = document.querySelectorAll('.revealreview')
      for (let i = 0; i < reveals.length; i++) {
          reveals[i].classList.remove('active')
      }
    }
  },
  mounted: function() {
    var min = 0
    var max = (this.reviews.length - 1)
    this.selectedReviewIndex = Math.floor(Math.random() * (max - min + 1) + min)
    setInterval(() => {
      this.rightClick()
    }, 7000)
  }
}
</script>
<style scoped>
.revealreview {
  position: relative;
  transform: translateY(0);
  opacity: 0;
  transition: 0.5s all ease;
}

.revealreview.active {
  transform: translateY(0);
  opacity: 1;
}

</style>
