<template>
      <div v-if="success === null">
        <form   class="needs-validation" novalidate v-on:submit.prevent="submitForm">
          <div class="row">
            <div class="col">
              <!-- Name input -->
              <div class="row mb-4">
                <div class="col">
                  <MDBInput type="text" v-model="form.name" label="Name*" required/>
                </div>
              </div>

              <!-- E-Mail input -->
              <div class="row mb-4">
                <div class="col">
                  <MDBInput type="email" v-model="form.email" label="E-Mail*" required/>
                </div>
              </div>

              <!-- Password input -->
              <div class="row mb-4" hidden>
                <div class="col">
                  <MDBInput type="text" v-model="form.surname" label="Surname"/>
                </div>
              </div>

              <!-- Password input -->
              <div class="row mb-4">
                <div class="col">
                  <MDBTextarea rows="5" v-model="form.message" label="Nachricht" required/>
                </div>
              </div>

              <!-- Submit button -->
              <MDBBtn type="submit" class="btn-primary btn-block mb-4">Senden</MDBBtn>
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <div class="row">
          <div class="col text-center">
            <p class="badge-success">Die Nachricht wurde erfolgreich übermittelt!</p>
          </div>
        </div>
      </div>
</template>

<script>
import UmzugBayernApi from '../api'
import { MDBInput, MDBTextarea, MDBBtn } from "mdb-vue-ui-kit"


export default {
  name: 'ContactForm',
  components: {
    MDBInput,
    MDBTextarea,
    MDBBtn
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        surname: "",
        message: "",
        msg: ""
      },
      error: null,
      success: null,
    };
  },
  created: function() {
    this.success = null
    this.error = null
    this.form.name = ""
    this.form.surname = ""
    this.form.email = ""
    this.form.message = ""
    this.form.msg = ""
  },
  methods: {
    validate() {
      //eslint-disable-next-line
      const emailRegexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if(!emailRegexp.test(this.form.email)) {
        return false
      }
      if(this.name === ""){
        return false
      }
      if(this.message === ""){
        return false
      }
      return true
    },
    submitForm(event) {
      event.target.classList.add("was-validated")
      if (this.validate()) {
        var y = new Date().getFullYear()
        var fe = this.form.email.charAt(0)
        var fm = this.form.message.charAt(0)
        var le = this.form.email.charAt(this.form.email.length - 1)
        var lm = this.form.message.charAt(this.form.message.length - 1)
        this.form.msg = fe + fm + y + le + lm
        if(null !== UmzugBayernApi.Contact(this.form)) {
          // console.log("success")
          this.success = true
          event.target.classList.remove("was-validated")
        }
      }
    }
  }
}
</script>
