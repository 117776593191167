import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/business',
    name: 'homebusiness',
    component: () => import(/* webpackChunkName: "homebusiness" */ '../views/HomeBusiness.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
  },
  {
    path: '/agb',
    name: 'agb',
    component: () => import(/* webpackChunkName: "agb" */ '../views/Agb.vue')
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import(/* webpackChunkName: "datenschutz" */ '../views/Datenschutz.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/ueber-umzug-bayern',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ueber-umzug-bayern" */ '../views/Articles/UeberUns.vue')
  },
  {
    path: '/umzug/:city',
    name: 'city',
    component: () => import(/* webpackChunkName: "city" */ '../views/City.vue')
  },
  /*
   * ARTICLES
   */
   {
     path: '/checkliste',
     name: 'checkliste',
     component: () => import(/* webpackChunkName: "checkliste" */ '../views/Articles/Checklist.vue'),
   },
   {
     path: '/bewertungen',
     name: 'bewertungen',
     component: () => import(/* webpackChunkName: "bewertungen" */ '../views/Articles/Bewertungen.vue'),
   },
   {
     path: '/unser-angebot',
     name: 'unser-angebot',
     component: () => import(/* webpackChunkName: "unserangebot" */ '../views/Articles/UnserAngebot.vue'),
   },
   {
     path: '/umzug',
     name: 'umzug',
     component: () => import(/* webpackChunkName: "umzug" */ '../views/Articles/Umzug.vue'),
   },
   {
     path: '/umzug-planen',
     name: 'umzug-planen',
     component: () => import(/* webpackChunkName: "umzugplanen" */ '../views/Articles/UmzugPlanen.vue'),
   },
   {
     path: '/umzugsfirma',
     name: 'umzugsfirma',
     component: () => import(/* webpackChunkName: "umzugsfirma" */ '../views/Articles/Umzugsfirma.vue'),
   },
   {
     path: '/umzug-muenchen-berlin',
     name: 'umzug-muenchen-berlin',
     component: () => import(/* webpackChunkName: "umzugmuenchenberlin" */ '../views/Articles/UmzugMuenchenBerlin.vue'),
   },
   {
     path: '/privatumzuege',
     name: 'privatumzuege',
     component: () => import(/* webpackChunkName: "privatumzuege" */ '../views/Articles/Privatumzuege.vue'),
   },
   {
     path: '/seniorenumzuege',
     name: 'seniorenumzuege',
     component: () => import(/* webpackChunkName: "seniorenumzuege" */ '../views/Articles/Seniorenumzuege.vue'),
   },
   {
     path: '/studentenumzug',
     name: 'studentenumzug',
     component: () => import(/* webpackChunkName: "studentenumzug" */ '../views/Articles/Studentenumzug.vue'),
   },
   {
     path: '/firmenumzuege',
     name: 'firmenumzuege',
     component: () => import(/* webpackChunkName: "firmenumzuege" */ '../views/Articles/Firmenumzuege.vue'),
   },
   {
     path: '/entruempelung',
     name: 'entruempelung',
     component: () => import(/* webpackChunkName: "entruempelung" */ '../views/Articles/Entruempelung.vue'),
   },
   {
     path: '/hausentruempelung',
     name: 'hausentruempelung',
     component: () => import(/* webpackChunkName: "hausentruempelung" */ '../views/Articles/Hausentruempelung.vue'),
   },
   {
     path: '/wohnungsentruempelung',
     name: 'wohnungsentruempelung',
     component: () => import(/* webpackChunkName: "wohnungsentruempelung" */ '../views/Articles/Wohnungsentruempelung.vue'),
   },
   {
     path: '/haushaltsaufloesung',
     name: 'haushaltsaufloesung',
     component: () => import(/* webpackChunkName: "haushaltsaufloesung" */ '../views/Articles/Haushaltsaufloesung.vue'),
   },
   {
     path: '/entkernung',
     name: 'entkernung',
     component: () => import(/* webpackChunkName: "entkernung" */ '../views/Articles/Entkernung.vue'),
   },
   {
     path: '/garagenentruempelung',
     name: 'garagenentruempelung',
     component: () => import(/* webpackChunkName: "garagenentruempelung" */ '../views/Articles/Garagenentruempelung.vue'),
   },
   {
     path: '/kellerentruempelung',
     name: 'kellerentruempelung',
     component: () => import(/* webpackChunkName: "kellerentruempelung" */ '../views/Articles/Kellerentruempelung.vue'),
   },
   {
     path: '/dachbodenentruempelung',
     name: 'dachbodenentruempelung',
     component: () => import(/* webpackChunkName: "dachbodenentruempelung" */ '../views/Articles/Dachbodenentruempelung.vue'),
   },
   {
     path: '/firmenentruempelung',
     name: 'firmenentruempelung',
     component: () => import(/* webpackChunkName: "firmenentruempelung" */ '../views/Articles/Firmenentruempelung.vue'),
   },
   {
     path: '/gewerbeentruempelungen',
     name: 'gewerbeentruempelungen',
     component: () => import(/* webpackChunkName: "gewerbeentruempelungen" */ '../views/Articles/Gewerbeentruempelungen.vue'),
   },
   {
     path: '/lagerentruempelung',
     name: 'lagerentruempelung',
     component: () => import(/* webpackChunkName: "lagerentruempelung" */ '../views/Articles/Lagerentruempelung.vue'),
   },
   {
     path: '/halteverbot',
     name: 'halteverbot',
     component: () => import(/* webpackChunkName: "halteverbot" */ '../views/Articles/Halteverbotszonen.vue'),
   },
   {
     path: '/tatortreinigung',
     name: 'tatortreinigung',
     component: () => import(/* webpackChunkName: "tatortreinigung" */ '../views/Articles/Tatortreinigung.vue'),
   },
   {
     path: '/messestand-transport-und-aufbau',
     name: 'messeaufbau',
     component: () => import(/* webpackChunkName: "fachmesse" */ '../views/Articles/Messeaufbau.vue'),
   },
   {
     path: '/moebelmontage',
     name: 'moebelmontage',
     component: () => import(/* webpackChunkName: "moebelmontage" */ '../views/Articles/Moebelmontage.vue'),
   },
   {
     path: '/umzugstipps',
     name: 'umzugstipps',
     // route level code-splitting
     // this generates a separate chunk (about.[hash].js) for this route
     // which is lazy-loaded when the route is visited.
     component: () => import(/* webpackChunkName: "umzugstipps" */ '../views/Articles/Umzugstipps.vue'),
     props: true
   },
   {
     path: '/faq',
     name: 'faq',
     component: () => import(/* webpackChunkName: "faq" */ '../views/Articles/FAQ.vue')
   },
   {
     path: '/preise',
     name: 'preise',
     component: () => import(/* webpackChunkName: "preise" */ '../views/Articles/Preise.vue')
   },
   {
     path: '/umzugsmaterial',
     name: 'umzugsmaterialien',
     component: () => import(/* webpackChunkName: "umzugsmaterialien" */ '../views/umzugsmaterial/Umzugsmaterial.vue')
   },
   {
     path: '/umzugsmaterial/:slug',
     name: 'umzugsmaterialien_item',
     component: () => import(/* webpackChunkName: "umzugsmaterialienitem" */ '../views/umzugsmaterial/UmzugsmaterialItem.vue')
   },
   {
     path: '/umzug-in-augsburg',
     name: 'umzug_in_augsburg',
     component: () => import(/* webpackChunkName: "umzuginaugsburg" */ '../views/Cities/UmzugAugsburg.vue')
   },
   {
     path: '/umzug-in-bamberg',
     name: 'umzug_in_bamberg',
     component: () => import(/* webpackChunkName: "umzuginbamberg" */ '../views/Cities/UmzugBamberg.vue')
   },
   {
     path: '/umzug-in-erding',
     name: 'umzug_in_erding',
     component: () => import(/* webpackChunkName: "umzuginerding" */ '../views/Cities/UmzugErding.vue')
   },
   {
     path: '/umzug-in-freising',
     name: 'umzug_in_freising',
     component: () => import(/* webpackChunkName: "umzuginfreising" */ '../views/Cities/UmzugFreising.vue')
   },
   {
     path: '/umzug-in-ingolstadt',
     name: 'umzug_in_ingolstadt',
     component: () => import(/* webpackChunkName: "umzuginingolstadt" */ '../views/Cities/UmzugIngolstadt.vue')
   },
   {
     path: '/umzug-in-landshut',
     name: 'umzug_in_landshut',
     component: () => import(/* webpackChunkName: "umzuginlandshut" */ '../views/Cities/UmzugLandshut.vue')
   },
   {
     path: '/umzug-in-muenchen',
     name: 'umzug_in_muenchen',
     component: () => import(/* webpackChunkName: "umzuginmuenchen" */ '../views/Cities/UmzugMuenchen.vue')
   },
   {
     path: '/umzug-in-nuernberg',
     name: 'umzug_in_nuernberg',
     component: () => import(/* webpackChunkName: "umzuginnuernberg" */ '../views/Cities/UmzugNuernberg.vue')
   },
   {
     path: '/umzug-in-poing',
     name: 'umzug_in_poing',
     component: () => import(/* webpackChunkName: "umzuginpoing" */ '../views/Cities/UmzugPoing.vue')
   },
   {
     path: '/umzug-in-rosenheim',
     name: 'umzug_in_rosenheim',
     component: () => import(/* webpackChunkName: "umzuginrosenheim" */ '../views/Cities/UmzugRosenheim.vue')
   },
   {
     path: '/umzug-in-straubing',
     name: 'umzug_in_straubing',
     component: () => import(/* webpackChunkName: "umzuginstraubing" */ '../views/Cities/UmzugStraubing.vue')
   },
   {
     path: '/umzug-in-tegernsee',
     name: 'umzug_in_tegernsee',
     component: () => import(/* webpackChunkName: "umzugintegernsee" */ '../views/Cities/UmzugTegernsee.vue')
   },
   {
     path: '/umzug-in-wuerzburg',
     name: 'umzug_in_wuerzburg',
     component: () => import(/* webpackChunkName: "umzuginwuerzburg" */ '../views/Cities/UmzugWuerzburg.vue')
   },
   {
     path: '/entruempelung-in-augsburg',
     name: 'entruempelung_in_augsburg',
     component: () => import(/* webpackChunkName: "entruempelunginaugsburg" */ '../views/Cities/EntruempelungAugsburg.vue')
   },
   {
     path: '/entruempelung-in-bamberg',
     name: 'entruempelung_in_bamberg',
     component: () => import(/* webpackChunkName: "entruempelunginbamberg" */ '../views/Cities/EntruempelungBamberg.vue')
   },
   {
     path: '/entruempelung-in-erding',
     name: 'entruempelung_in_erding',
     component: () => import(/* webpackChunkName: "entruempelunginerding" */ '../views/Cities/EntruempelungErding.vue')
   },
   {
     path: '/entruempelung-in-freising',
     name: 'entruempelung_in_freising',
     component: () => import(/* webpackChunkName: "entruempelunginfreising" */ '../views/Cities/EntruempelungFreising.vue')
   },
   {
     path: '/entruempelung-in-ingolstadt',
     name: 'entruempelung_in_ingolstadt',
     component: () => import(/* webpackChunkName: "entruempelunginingolstadt" */ '../views/Cities/EntruempelungIngolstadt.vue')
   },
   {
     path: '/entruempelung-in-landshut',
     name: 'entruempelung_in_landshut',
     component: () => import(/* webpackChunkName: "entruempelunginlandshut" */ '../views/Cities/EntruempelungLandshut.vue')
   },
   {
     path: '/entruempelung-in-muenchen',
     name: 'entruempelung_in_muenchen',
     component: () => import(/* webpackChunkName: "entruempelunginmuenchen" */ '../views/Cities/EntruempelungMuenchen.vue')
   },
   {
     path: '/entruempelung-in-nuernberg',
     name: 'entruempelung_in_nuernberg',
     component: () => import(/* webpackChunkName: "entruempelunginnuernberg" */ '../views/Cities/EntruempelungNuernberg.vue')
   },
   {
     path: '/entruempelung-in-poing',
     name: 'entruempelung_in_poing',
     component: () => import(/* webpackChunkName: "entruempelunginpoing" */ '../views/Cities/EntruempelungPoing.vue')
   },
   {
     path: '/entruempelung-in-rosenheim',
     name: 'entruempelung_in_rosenheim',
     component: () => import(/* webpackChunkName: "entruempelunginrosenheim" */ '../views/Cities/EntruempelungRosenheim.vue')
   },
   {
     path: '/entruempelung-in-straubing',
     name: 'entruempelung_in_straubing',
     component: () => import(/* webpackChunkName: "entruempelunginstraubing" */ '../views/Cities/EntruempelungStraubing.vue')
   },
   {
     path: '/entruempelung-in-starnberg',
     name: 'entruempelung_in_starnberg',
     component: () => import(/* webpackChunkName: "entruempelunginstarnberg" */ '../views/Cities/EntruempelungStarnberg.vue')
   },
   {
     path: '/entruempelung-in-tegernsee',
     name: 'entruempelung_in_tegernsee',
     component: () => import(/* webpackChunkName: "entruempelungintegernsee" */ '../views/Cities/EntruempelungTegernsee.vue')
   },
   {
     path: '/entruempelung-in-wuerzburg',
     name: 'entruempelung_in_wuerzburg',
     component: () => import(/* webpackChunkName: "entruempelunginwuerzburg" */ '../views/Cities/EntruempelungWuerzburg.vue')
   },
   {
     path: '/halteverbotsschilder-in-augsburg',
     name: 'halteverbotsschilder_in_augsburg',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinaugsburg" */ '../views/Cities/HalteverbotAugsburg.vue')
   },
   {
     path: '/halteverbotsschilder-in-bamberg',
     name: 'halteverbotsschilder_in_bamberg',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinbamberg" */ '../views/Cities/HalteverbotBamberg.vue')
   },
   {
     path: '/halteverbotsschilder-in-erding',
     name: 'halteverbotsschilder_in_erding',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinerding" */ '../views/Cities/HalteverbotErding.vue')
   },
   {
     path: '/halteverbotsschilder-in-freising',
     name: 'halteverbotsschilder_in_freising',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinfreising" */ '../views/Cities/HalteverbotFreising.vue')
   },
   {
     path: '/halteverbotsschilder-in-ingolstadt',
     name: 'halteverbotsschilder_in_ingolstadt',
     component: () => import(/* webpackChunkName: "halteverbotsschilderiningolstadt" */ '../views/Cities/HalteverbotIngolstadt.vue')
   },
   {
     path: '/halteverbotsschilder-in-landshut',
     name: 'halteverbotsschilder_in_landshut',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinlandshut" */ '../views/Cities/HalteverbotLandshut.vue')
   },
   {
     path: '/halteverbotsschilder-in-muenchen',
     name: 'halteverbotsschilder_in_muenchen',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinmuenchen" */ '../views/Cities/HalteverbotMuenchen.vue')
   },
   {
     path: '/halteverbotsschilder-in-nuernberg',
     name: 'halteverbotsschilder_in_nuernberg',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinnuernberg" */ '../views/Cities/HalteverbotNuernberg.vue')
   },
   {
     path: '/halteverbotsschilder-in-rosenheim',
     name: 'halteverbotsschilder_in_rosenheim',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinrosenheim" */ '../views/Cities/HalteverbotRosenheim.vue')
   },
   {
     path: '/halteverbotsschilder-in-straubing',
     name: 'halteverbotsschilder_in_straubing',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinstraubing" */ '../views/Cities/HalteverbotStraubing.vue')
   },
   {
     path: '/halteverbotsschilder-in-tegernsee',
     name: 'halteverbotsschilder_in_tegernsee',
     component: () => import(/* webpackChunkName: "halteverbotsschilderintegernsee" */ '../views/Cities/HalteverbotTegernsee.vue')
   },
   {
     path: '/halteverbotsschilder-in-wuerzburg',
     name: 'halteverbotsschilder_in_wuerzburg',
     component: () => import(/* webpackChunkName: "halteverbotsschilderinwuerzburg" */ '../views/Cities/HalteverbotWuerzburg.vue')
   },
   {
     path: '/umzugsratgeber',
     name: 'blog',
     component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
   },
   {
     path: '/umzugsratgeber/:slug',
     name: 'blogpost',
     component: () => import(/* webpackChunkName: "blog" */ '../views/BlogPost.vue'),
   },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      requiresNotAuth: true,
    }
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    /* register moved to login to prevent new user from registering */
    component: () => import(/* webpackChunkName: "register_login" */ '../views/Login.vue'),
    meta: {
      requiresNotAuth: true,
    }
  },
  {
    path: '/verify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verify" */ '../views/Verify.vue'),
    props: true
  },
  {
    path: '/passwortreset',
    name: 'passwordreset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "passwordreset" */ '../views/PasswordReset.vue'),
    props: true,
    meta: {
      requiresNotAuth: true,
    }
  },
  {
    path: '/profil',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:  () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/favoriten',
    name: 'favorites',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "favorites" */ '../views/Favoriten.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/rechnungen',
    name: 'invoices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/Invoices.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/rechnungen/neu',
    name: 'createinvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "createinvoice" */ '../views/Invoices/CreateInvoice.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/kalender',
    name: 'calendar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/einstellungen',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '../views/User/Settings.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/bestellen',
    name: 'bestellen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bestellen" */ '../views/Bestellen.vue'),
    meta: {
      requiresAuth: false,
      requiresVerification: false,
    }
  },
  {
    path: '/suche',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/request/:slug',
    name: 'request',
    component: () => import(/* webpackChunkName: "request" */ '../views/Request.vue'),
    meta: {
      requiresAuth: true,
      requiresVerification: true,
    }
  },
  {
    path: '/anfrage',
    name: 'requestform',
    component: () => import(/* webpackChunkName: "requestform" */ '../views/RequestForm.vue'),
    meta: {
      requiresAuth: false,
      requiresVerification: false,
    }
  },
  {
    path: '/anfrage-entruempelung',
    name: 'requestform-entruempelung',
    component: () => import(/* webpackChunkName: "requestform-entruempelung" */ '../views/RequestFormEntruempelung.vue'),
    meta: {
      requiresAuth: false,
      requiresVerification: false,
    }
  },
  { path: '/404', component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')},
  { path: '/:pathMatch(.*)*', redirect: '/404' },
]

const router = createRouter({
  scrollBehavior(/* to, from, savedPosition */) {
      // always scroll to top
      return { top: 0 }
    },
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  //window.scrollTo(0, 0)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresNotAuth = to.matched.some(x => x.meta.requiresNotAuth)
  const requiresVerification = to.matched.some(x => x.meta.requiresVerification)
  //const requiresAuthVerification = to.matched.some(x => x.meta.requiresAuthVerification)

  if (requiresAuth && (store.state.user_authenticated == false)) {
    /// not logged in - requires Login
    // route to login
    //next('/login')
    next({
        path: '/login',
        query: {
           nextUrl: to.fullPath,
        }
    })
  } else if (requiresAuth && (store.state.user_authenticated == true)) {
    // logged in - requires login
    if (requiresVerification && (store.state.user_verified == false)) {
      // logged in - not verified - requires verification
      // route to veirification
      next('/verify')
    } else {

      // logged in and verified - requires login -
      // route to next -> user is logged in and verified -> full access
      next()

    }
  } else if (requiresNotAuth && (store.state.user_authenticated == true)) {
    //Route only for not auth users (for example register page is only for not logged in users)
    //move to dashboard of logged in user
    next('/suche')
  } else {
    //route does not require Auth
    //route does not require NOT Auth
    next()
  }
})

export default router
