import { createStore } from 'vuex'
import router from '../router'
import UmzugBayernApi from '../api'
import createPersistedState from "vuex-persistedstate"
import axios from 'axios'


// Create a new store instance.
const store = createStore({
  plugins: [createPersistedState()],
  state: {
    user: {},
    user_authenticated: false,
    user_verified: false
  },
  getters: {
      getState: state => {
        return state
      }
    },
  mutations: {
    setUser(state, val) {
      if(val == null) {
        state.user_authenticated = false
        state.user_verified = false
        axios.defaults.headers.common['Authorization'] = ""
      } else {
        state.user_authenticated = true
        if(val.email_verified_at !== null) {
          state.user_verified = true
        }
        axios.defaults.headers.common['Authorization'] = "Bearer" + val.token
        if(router.currentRoute._rawValue.query !== undefined && router.currentRoute._rawValue.query.nextUrl !== undefined) {
          router.push(router.currentRoute._rawValue.query.nextUrl)
        } else {
          router.push({ name: 'dashboard'})
        }

      }
      state.user = val
    },

    setSearch(state, val) {
      if(state.user !== null) {
        state.user.search = val
      }
    }
  },
  actions: {
    async login({ dispatch }, form) {
      //Vue.log('Login')
      //const { user } = await UmzugBayernApi.Login(form.email, form.password);
      var user = await UmzugBayernApi.Login(form.email, form.password);
      //Vue.log(user)
      // fetch user profile and set in state
      if(user !== null) {
        dispatch('fetchUser', user)

      } else {
        if(form.callback) form.callback("Beim Login ist ein Fehler aufgetreten. Bitte kontrollieren Sie Ihre Daten und versuchen Sie es erneut.")
      }

    },
    async logout({ dispatch }, forwardurl) {
      await UmzugBayernApi.Logout();
      dispatch('fetchUser', null)
      if(forwardurl) {
        router.push(forwardurl)
        router.go()
      } else {
        router.push({ name: 'home'})
      }

    },
    async fetchUser({ commit }, user) {
      commit('setUser', user)
    }
  }
})

export default store
